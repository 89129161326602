<template>
  <div>
    <vs-row class="items-start">
      <vs-col
        vs-type="flex"
        vs-justify="center"
        vs-align="center"
        vs-w="12"
        class="pr-5"
      >
        <vx-card class="custom-vx-card">
          <vs-table :data="paymentRequests">
            <template slot="thead">
              <vs-th>
                Date sent
              </vs-th>
              <vs-th>
                Account
              </vs-th>
              <vs-th>
                Your reference
              </vs-th>
              <vs-th>
                Amount
              </vs-th>
              <vs-th>
                Customer
              </vs-th>
              <vs-th>
                Request type
              </vs-th>
              <vs-th>
                Status
              </vs-th>
              <vs-th> </vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="tr.createdAt">
                  {{ moment(tr.createdAt) }}
                </vs-td>
                <vs-td :data="tr.account">
                  {{ tr.bank ? tr.bank.accountName : "-" }}
                </vs-td>
                <vs-td :data="tr.reference">
                  {{ tr.reference }}
                </vs-td>
                <vs-td :data="tr.amount">
                  {{ moneyFormat(tr.amount) }}
                </vs-td>
                <vs-td :data="tr.payeeName">
                  {{ tr.payeeName }}
                </vs-td>
                <vs-td :data="tr.requestType">
                  {{ tr.requestType ? tr.requestType : "One-off" | capitalize }}
                </vs-td>
                <vs-td :data="tr.paymentStatus">
                  {{ tr.paymentStatus === 'Completed'? 'Completed': 'Complete' }}
                </vs-td>
                <vs-td :data="tr">
                  <a
                    @click="viewDetail(tr)"
                    class="underline cursor-pointer"
                    >View</a
                  >
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div>
            <vs-pagination
              :total="totalPage"
              v-model="pageNumber"
            ></vs-pagination>
          </div>
        </vx-card>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import moment from "moment";

export default {
  data() {
    return {
      pageNumber: 1,
      totalData: 0,
      limit: 50,
      paymentRequests:[]
    };
  },
  methods: {
    ...mapActions("paymentRequest", [
      "fetchPaymentRequestListByPartnerId",
    ]),
    ...mapActions("paymentType", ["fetchPaymentType"]),
    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },
    moneyFormat(data) {
      return `$ ${parseFloat(data)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
    },
    momentTime(date) {
      return moment(date).format("h:mm:ss");
    },
    async getPaymentRequestList(partnerId, page) {
      await this.fetchPaymentRequestListByPartnerId(`${partnerId}?page=${page}`)
        .then(result => {
          this.paymentRequests = result.data.data.docs;
          this.totalData = result.data.data.pagination.total
            ? result.data.data.pagination.total
            : 0;
        })
        .catch(err => {});
    },
    showPaymentPopup(pageId) {
      this.selectedPageId = pageId;
      this.paymentRequestPopup = true;
    },
    showMessage(title, message, color, icon) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,

      });
    },
    viewDetail(data) {
      this.$router.push({
        name: "staff-payment-requests-detail",
        params: { id: data._id }
      });
    },
  },
  mounted() {
    this.getPaymentRequestList(this.partnerId, 1);
  },
  watch: {
    pageNumber(page) {
      this.getPaymentRequestList(this.partnerId, page);
    }
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    },
    totalPage: function() {
      return Math.ceil(this.totalData / this.limit);
    }
  }
};
</script>
